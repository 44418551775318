var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="CDB-Dropdown-list CDB-Text CDB-Size-medium">\n\n  ';
 if (flags.canCollapse) { ;
__p += '\n    <li class="CDB-Dropdown-item">\n      <button class="CDB-Dropdown-link js-toggleCollapsed">\n        Toggle widget\n          <div class="CDB-Dropdown-toggle">\n          ';
 if (collapsed) { ;
__p += '\n            <input class="CDB-Toggle js-inputCollapsed" checked="checked" type="checkbox" name="collapsed">\n          ';
 } else { ;
__p += '\n            <input class="CDB-Toggle js-inputCollapsed" type="checkbox" name="collapsed">\n          ';
 } ;
__p += '\n            <span class="u-iBlock CDB-ToggleFace"></span>\n          </div>\n      </button>\n    </li>\n  ';
 } ;
__p += '\n\n  ';
 if (flags.localTimezone) { ;
__p += '\n  <li class="CDB-Dropdown-item">\n    <button class="CDB-Dropdown-link js-toggleLocalTimezone">\n      Local Time Zone\n      <div class="CDB-Dropdown-toggle">\n        ';
 if (local_timezone) { ;
__p += '\n          <input class="CDB-Toggle u-iBlock js-localTimezone" checked="checked" type="checkbox" name="localtimezone">\n        ';
 } else { ;
__p += '\n          <input class="CDB-Toggle u-iBlock js-localTimezone" type="checkbox" name="localtimezone">\n        ';
 } ;
__p += '\n        <span class="u-iBlock CDB-ToggleFace"></span>\n      </div>\n    </button>\n  </li>\n  ';
 } ;
__p += '\n\n  ';
 if (flags.normalizeHistogram) { ;
__p += '\n  <li class="CDB-Dropdown-item">\n    <button class="CDB-Dropdown-link js-toggleNormalized">\n      Show totals\n      <div class="CDB-Dropdown-toggle">\n        ';
 if (normalized) { ;
__p += '\n          <input class="CDB-Toggle u-iBlock js-inputNormalized" type="checkbox" name="normalized">\n        ';
 } else { ;
__p += '\n          <input class="CDB-Toggle u-iBlock js-inputNormalized" checked="checked" type="checkbox" name="normalized">\n        ';
 } ;
__p += '\n        <span class="u-iBlock CDB-ToggleFace"></span>\n      </div>\n    </button>\n  </li>\n  ';
 } ;
__p += '\n\n  ';
 if (show_options) { ;
__p += '\n    <li class="CDB-Dropdown-item">\n      <button type="button" class="CDB-Dropdown-link u-ellipsis u-actionTextColor js-editWidget" title="Edit">Edit</button>\n    </li>\n    <li class="CDB-Dropdown-item">\n      <button type="button" class="CDB-Dropdown-link u-ellipsis u-errorTextColor js-removeWidget" title="Delete...">Delete...</button>\n    </li>\n  ';
 } ;
__p += '\n</ul>\n';

}
return __p
}