var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<p class="CDB-Text CDB-Size-large u-secondaryTextColor u-iBlock">\n  ' +
__e( time ) +
'\n</p>\n';

}
return __p
}