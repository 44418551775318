var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="CDB-Widget-listItem">\n  <h4 class="CDB-Text CDB-Size-large">No results</h4>\n  <p class="CDB-Text CDB-Size-medium u-tSpace-xl u-altTextColor">Your search "' +
__e( q ) +
'" didn\'t match<br/>with any value.</p>\n  <p class="CDB-Text CDB-Size-medium u-tSpace-xl u-altTextColor">Try searching again.</p>\n</li>\n';

}
return __p
}