var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-header js-header">\n  <div class="js-title"></div>\n\n  ';
 if (showSource) { ;
__p += '\n    <div class="CDB-Widget-contentSpaced CDB-Widget-contentFull">\n      <dl class="CDB-Widget-info u-tSpace">\n        <div class="u-flex u-alignCenter u-ellipsis">\n          <span class="CDB-Text CDB-Size-small is-semibold u-upperCase" style="color: ' +
__e( sourceColor ) +
';">\n            ' +
__e( sourceId ) +
'\n          </span>\n\n          ';
 if (!isSourceType) { ;
__p += '\n            <span class="CDB-Text CDB-Size-small u-lSpace--s u-flex" style="color: ' +
__e( sourceColor ) +
';">\n              <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>\n            </span>\n          ';
 } ;
__p += '\n\n          <span class="CDB-Text CDB-Size-small u-mainTextColor u-lSpace">\n            ' +
((__t = ( sourceType )) == null ? '' : __t) +
'\n          </span>\n\n          <span class="CDB-Text CDB-Size-small u-altTextColor u-ellipsis u-lSpace" title="' +
((__t = ( layerName )) == null ? '' : __t) +
'">\n            ' +
((__t = ( layerName )) == null ? '' : __t) +
'\n          </span>\n        </div>\n      </dl>\n    </div>\n  ';
 } ;
__p += '\n</div>\n\n<div class="CDB-Widget-content CDB-Widget-content--timeSeries js-content"></div>\n';

}
return __p
}