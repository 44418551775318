var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<p class="CDB-Text CDB-Size-large js-widget-title u-iBlock u-ellipsis u-rSpace">' +
__e( title ) +
'</p>\n<div class="CDB-Widget-contentSpaced--end">\n  <div class="CDB-Widget-options CDB-Widget-contentSpaced">\n    <p class="CDB-Text CDB-Size-small is-semibold u-upperCase u-rSpace--m js-val">…</p>\n\n    ';
 if (showSelection && start && end) { ;
__p += '\n      <div class="CDB-Chart-staticTips">\n        <div class="CDB-Chart-staticTip u-iBlock u-rSpace">\n          <p class="CDB-Text CDB-Size-small CDB-Chart-staticTipText">' +
((__t = ( start )) == null ? '' : __t) +
'</p>\n        </div>\n\n        <p class="CDB-Text CDB-Size-small is-semibold u-upperCase u-iBlock u-rSpace">to</p>\n\n        <div class="CDB-Chart-staticTip u-iBlock">\n          <p class="CDB-Text CDB-Size-small CDB-Chart-staticTipText">' +
((__t = ( end )) == null ? '' : __t) +
'</p>\n        </div>\n      </div>\n    ';
 } ;
__p += '\n\n    ';
 if (showClearButton) { ;
__p += '\n      <button class="CDB-Text CDB-Size-small is-semibold u-upperCase u-actionTextColor CDB-Widget-filterButton js-clear">Clear</button>\n    ';
 } ;
__p += '\n    <button class="CDB-Shape CDB-Widget-actions js-actions u-lSpace" data-tooltip="More options">\n      <div class="CDB-Shape-threePoints is-blue is-small">\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n      </div>\n    </button>\n  </div>\n</div>\n';

}
return __p
}