var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (isSearchEnabled) { ;
__p += '\n  <form class="CDB-Widget-search js-form">\n    <div class="CDB-Shape CDB-Widget-searchLens u-iBlock u-rSpace js-searchIcon">\n      <span class="CDB-Shape-magnify is-small is-blue"></span>\n    </div>\n    <input type="text" class="CDB-Text CDB-Size-large CDB-Widget-textInput CDB-Widget-searchTextInput js-textInput" value="' +
__e( q ) +
'" placeholder="Search by ' +
__e( columnName ) +
'"/>\n    ';
 if (canShowApply) { ;
__p += '\n      <button type="button" class="CDB-Text is-semibold u-upperCase CDB-Size-small CDB-Widget-searchApply js-applyLocked u-actionTextColor">apply</button>\n    ';
 } ;
__p += '\n  </form>\n';
 } else { ;
__p += '\n  <div class="CDB-Widget-title CDB-Widget-contentSpaced js-title">\n    <h3 class="CDB-Text CDB-Size-large u-ellipsis js-titleText" title="' +
__e( title ) +
'">' +
__e( title ) +
'</h3>\n    <div class="CDB-Widget-options CDB-Widget-contentSpaced">\n      ';
 if (isAutoStyleEnabled) { ;
__p += '\n        <button class="CDB-Widget-buttonIcon CDB-Widget-buttonIcon--circle js-colors\n          ' +
__e( isAutoStyle ? 'is-selected' : '' ) +
'\n          ' +
__e( isAutoStyle ? 'js-cancelAutoStyle' : 'js-autoStyle' ) +
'\n          " data-tooltip="\n            ' +
__e( isAutoStyle ? 'Remove Auto style' : 'Apply Auto Style' ) +
'\n          ">\n          <i class="CDB-IconFont CDB-IconFont-drop CDB-IconFont--small CDB-IconFont--top"></i>\n        </button>\n      ';
 } ;
__p += '\n      <button class="CDB-Shape CDB-Widget-actions js-actions u-lSpace" data-tooltip="More options">\n        <div class="CDB-Shape-threePoints is-blue is-small">\n          <div class="CDB-Shape-threePointsItem"></div>\n          <div class="CDB-Shape-threePointsItem"></div>\n          <div class="CDB-Shape-threePointsItem"></div>\n        </div>\n      </button>\n    </div>\n  </div>\n  ';
 if (showSource) { ;
__p += '\n    <dl class="CDB-Widget-info u-tSpace">\n      <div class="u-flex u-alignCenter u-ellipsis">\n        <span class="CDB-Text CDB-Size-small is-semibold u-upperCase" style="color: ' +
__e( sourceColor ) +
';">\n          ' +
__e( sourceId ) +
'\n        </span>\n\n        ';
 if (!isSourceType) { ;
__p += '\n          <span class="CDB-Text CDB-Size-small u-lSpace--s u-flex" style="color: ' +
__e( sourceColor ) +
';">\n            <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>\n          </span>\n        ';
 } ;
__p += '\n\n        <span class="CDB-Text CDB-Size-small u-mainTextColor u-lSpace">\n          ' +
((__t = ( sourceType )) == null ? '' : __t) +
'\n        </span>\n\n        <span class="CDB-Text CDB-Size-small u-altTextColor u-ellipsis u-lSpace" title="' +
((__t = ( layerName )) == null ? '' : __t) +
'">\n          ' +
((__t = ( layerName )) == null ? '' : __t) +
'\n        </span>\n      </div>\n    </dl>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}