var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="CDB-Widget-contentFlex">\n  <button class="u-rSpace--m CDB-Text is-semibold u-upperCase CDB-Size-small js-searchToggle">\n    <div class="CDB-Shape u-iBlock">\n      <span class="CDB-Shape-magnify is-small is-blue"></span>\n    </div>\n    <span class="u-iBlock u-actionTextColor">\n      search in ' +
__e( categoriesCount ) +
' categor' +
__e( categoriesCount === 1 ? 'y' : 'ies' ) +
'\n    </span>\n  </button>\n</div>\n\n';

}
return __p
}