var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-title CDB-Widget-contentSpaced">\n  <h3 class="CDB-Text CDB-Size-large u-ellipsis" title="' +
__e( title ) +
'">' +
__e( title ) +
'</h3>\n  <div class="CDB-Widget-options CDB-Widget-contentSpaced">\n    ';
 if (isAutoStyleEnabled) { ;
__p += '\n      <button class="CDB-Widget-buttonIcon CDB-Widget-buttonIcon--circle js-sizes\n        ' +
__e( isAutoStyle ? 'is-selected' : '' ) +
'\n        ' +
__e( isAutoStyle ? 'js-cancelAutoStyle' : 'js-autoStyle' ) +
'\n        " data-tooltip="' +
__e( isAutoStyle ? 'Remove Auto style' : 'Apply Auto Style' ) +
'">\n        <i class="CDB-IconFont CDB-IconFont-drop CDB-IconFont--small CDB-IconFont--top"></i>\n      </button>\n    ';
 } ;
__p += '\n    <button class="CDB-Shape CDB-Widget-actions js-actions u-lSpace" data-tooltip="More options">\n      <div class="CDB-Shape-threePoints is-blue is-small">\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n      </div>\n    </button>\n  </div>\n</div>\n';

}
return __p
}