var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
__e( value ) +
' ' +
__e( suffix ) +
'\n';

}
return __p
}