var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( placeholder )) == null ? '' : __t) +
'\n<button class="CDB-Button CDB-Button--secondary CDB-Button--medium CDB-Widget-errorButton js-refresh">\n  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">REFRESH</span>\n</button>\n\n';

}
return __p
}