var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (showMenu && showLegends) { ;
__p += '\n  <header class="CDB-Embed-header ';
 if (!showLegends) { ;
__p += ' CDB-Embed-header--shadow ';
 } ;
__p += '">\n    <h1 class="CDB-Text CDB-Size-large u-ellipsis" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\n    ';
 if (description) { ;
__p += '<div class="CDB-Embed-description CDB-Text CDB-Size-medium u-altTextColor" title="' +
((__t = ( description )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</div>';
 } ;
__p += '\n  </header>\n';
 } ;
__p += '\n\n';
 if (showLegends) { ;
__p += '\n  <div class="CDB-Embed-tabs CDB-NavMenu js-tabs"></div>\n';
 } ;
__p += '\n\n<div class="CDB-Embed-content">\n  <div class="CDB-Embed-tab ';
 if (showMenu) { ;
__p += ' CDB-Embed-tab--menu ';
 } ;
__p += ' is-active js-embed-map"></div>\n\n  ';
 if (showLegends) { ;
__p += '\n    <div class="CDB-Embed-tab CDB-Embed-legends js-embed-legends"></div>\n  ';
 } ;
__p += '\n</div>\n';
 if (showBanner) { ;
__p += '\n<div class="CDB-Embed-banner js-embed-banner"></div>\n';
 } ;
__p += '\n';

}
return __p
}