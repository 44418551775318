var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="CDB-Widget-chart CDB-Widget-chart--fake">\n  ';
 for (var i = 0; i < 15; i++) { ;
__p += '\n  <li class="CDB-Widget-chartItem CDB-Widget-chartItem--' +
__e( _.sample(['small', 'medium', 'big'], 1)[0] ) +
' CDB-Widget-chartItem--fake"></li>\n  ';
 } ;
__p += '\n</ul>\n';

}
return __p
}