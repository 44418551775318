var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (isSearchEnabled) { ;
__p += '\n  <p class="CDB-Text is-semibold CDB-Size-small u-upperCase js-lockCategories">' +
__e( totalLocked ) +
' selected</p>\n';
 } else { ;
__p += '\n  <p class="CDB-Text is-semibold CDB-Size-small u-upperCase js-textInfo">\n    ';
 if (isLocked) { ;
__p += '\n      ' +
__e( totalCats ) +
' blocked <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor CDB-Widget-link u-lSpace js-unlock">unlock</button>\n    ';
 } else { ;
__p += '\n      ';
 if (noneSelected) { ;
__p += '\n        None selected\n      ';
 } else { ;
__p += '\n        ' +
__e( allSelected ? "All selected" : acceptedCats + " selected" ) +
'\n        ';
 if (canBeLocked) { ;
__p += '\n          <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor CDB-Widget-link u-lSpace js-lock">lock</button>\n        ';
 };
__p += '\n      ';
 };
__p += '\n    ';
 } ;
__p += '\n  </p>\n  ';
 if (canSelectAll) { ;
__p += '\n    <div class="CDB-Widget-filterButtons">\n      <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor CDB-Widget-link CDB-Widget-filterButton js-all">all</button>\n    </div>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}