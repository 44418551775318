var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( prefix )) == null ? '' : __t) +
'' +
((__t = ( value )) == null ? '' : __t) +
'' +
((__t = ( suffix )) == null ? '' : __t) +
'\n';

}
return __p
}