var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="CDB-NavMenu-item js-tab';
 if (isSelected) { ;
__p += ' is-selected';
 } ;
__p += '" data-tab="' +
((__t = ( name )) == null ? '' : __t) +
'">\n    <button class="CDB-NavMenu-link u-upperCase">' +
((__t = ( title )) == null ? '' : __t) +
'</button>\n</li>';

}
return __p
}