var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div <div class="CDB-Embed-banner__inner">\n  <div class="CDB-Embed-banner__close js-close"></div>\n  <p class="CDB-Embed-banner__title">Want to create maps like this?</p>\n  <a class="CDB-Embed-banner__button" href="' +
((__t = ( startNowUrl )) == null ? '' : __t) +
'" target="_blank" rel="noopener noreferrer"><span>Start now</span></a>\n  <a class="CDB-Embed-banner__link" href="' +
((__t = ( removeBannerUrl )) == null ? '' : __t) +
'" target="_blank" rel="noopener noreferrer">Remove this banner</a>\n</div>\n';

}
return __p
}