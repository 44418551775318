var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (isSearchEnabled) { ;
__p += '\n    ';
 if (isSearchApplied) { ;
__p += '\n      <dt class="CDB-Widget-infoCount">' +
__e( resultsCount ) +
'</dt><dd class="CDB-Widget-infoDescription">found</dd>\n    ';
 } else { ;
__p += '\n      &nbsp;\n    ';
 } ;
__p += '\n  </dt>\n';
 } else { ;
__p += '\n  <dt class="CDB-Widget-infoCount">' +
__e( nullsPer ) +
'%</dt><dd class="CDB-Widget-infoDescription"> null rows</dd>\n  <dt class="CDB-Widget-infoCount"><span class="js-cats">' +
__e( catsPer ) +
'</span>%</dt><dd class="CDB-Widget-infoDescription">of total</dd>\n';
 } ;
__p += '\n';

}
return __p
}