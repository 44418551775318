var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-header js-header">\n  <div class="js-title"></div>\n  ';
 if (showSource) { ;
__p += '\n    <dl class="CDB-Widget-info u-tSpace">\n      <div class="u-flex u-alignCenter u-ellipsis">\n        <span class="CDB-Text CDB-Size-small is-semibold u-upperCase" style="color: ' +
__e( sourceColor ) +
';">\n          ' +
__e( sourceId ) +
'\n        </span>\n\n        ';
 if (!isSourceType) { ;
__p += '\n          <span class="CDB-Text CDB-Size-small u-lSpace--s u-flex" style="color: ' +
__e( sourceColor ) +
';">\n            <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>\n          </span>\n        ';
 } ;
__p += '\n\n        <span class="CDB-Text CDB-Size-small u-mainTextColor u-lSpace">\n          ' +
((__t = ( sourceType )) == null ? '' : __t) +
'\n        </span>\n\n        <span class="CDB-Text CDB-Size-small u-altTextColor u-ellipsis u-lSpace" title="' +
((__t = ( layerName )) == null ? '' : __t) +
'">\n          ' +
((__t = ( layerName )) == null ? '' : __t) +
'\n        </span>\n      </div>\n    </dl>\n  ';
 } ;
__p += '\n  ';
 if (showStats) { ;
__p += '\n    <dl class="CDB-Widget-info CDB-Text CDB-Size-small u-secondaryTextColor u-upperCase u-tSpace">\n      ';
 if (showNulls) { ;
__p += '\n      <dt class="CDB-Widget-infoCount js-nulls">0</dt><dd class="CDB-Widget-infoDescription">NULL ROWS</dd>\n      ';
 } ;
__p += '\n      <dt class="CDB-Widget-infoCount js-min">0</dt><dd class="CDB-Widget-infoDescription">MIN</dd>\n      <dt class="CDB-Widget-infoCount js-avg">0</dt><dd class="CDB-Widget-infoDescription">AVG</dd>\n      <dt class="CDB-Widget-infoCount js-max">0</dt><dd class="CDB-Widget-infoDescription">MAX</dd>\n    </dl>\n  ';
 } ;
__p += '\n</div>\n\n<div class="CDB-Widget-content CDB-Widget-content--histogram js-content">\n  <div class="CDB-Widget-filter CDB-Widget-contentSpaced ">\n    <p class="CDB-Text CDB-Size-small is-semibold u-upperCase js-val">…</p>\n    <div class="CDB-Widget-filterButtons js-filter is-hidden">\n      <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor CDB-Widget-filterButton js-zoom">zoom</button>\n      <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor CDB-Widget-filterButton js-clear">clear</button>\n    </div>\n  </div>\n  <div class="js-mini-chart"></div>\n  <div class="CDB-Chart--histogram js-chart"></div>\n</div>\n';

}
return __p
}