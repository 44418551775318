var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<button type="button" class="CDB-Widget-listItemInner CDB-Widget-listButton js-button ' +
__e( isDisabled ? 'is-disabled' : '' ) +
'">\n  <div class="CDB-Widget-contentSpaced">\n    <p class="CDB-Text is-semibold u-upperCase CDB-Size-medium u-ellipsis u-rSpace--xl" title="' +
__e( name ) +
'">' +
__e( name ) +
'</p>\n    <p class="CDB-Text CDB-Size-small u-secondaryTextColor" title="' +
__e( value ) +
'">' +
__e( prefix ) +
'' +
__e( formattedValue ) +
'' +
__e( suffix ) +
'</p>\n  </div>\n  <div class="CDB-Widget-progressBar">\n    <div class="CDB-Widget-progressState ' +
__e( isAggregated ? 'CDB-Widget-progressState--pattern' : '' ) +
' ' +
__e( isAccepted ? 'is-accepted' : '' ) +
'" style="width: ' +
__e( percentage ) +
'%; background-color: ' +
__e( color ) +
'"></div>\n  </div>\n</button>\n';

}
return __p
}