var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-header js-header">\n  <div class="CDB-Widget-title CDB-Widget-contentSpaced">\n    <h3 class="CDB-Text CDB-Size-large u-ellipsis js-title ' +
__e( isCollapsed ? 'is-collapsed' : '' ) +
'">' +
__e( title ) +
'</h3>\n    <div class="CDB-Widget-options">\n      <button class="CDB-Shape CDB-Widget-actions js-actions" data-tooltip="More options">\n        <div class="CDB-Shape-threePoints is-blue is-small">\n          <div class="CDB-Shape-threePointsItem"></div>\n          <div class="CDB-Shape-threePointsItem"></div>\n          <div class="CDB-Shape-threePointsItem"></div>\n        </div>\n      </button>\n    </div>\n  </div>\n  ';
 if (showSource) { ;
__p += '\n    <dl class="CDB-Widget-info u-tSpace">\n      <div class="u-flex u-alignCenter u-ellipsis">\n        <span class="CDB-Text CDB-Size-small is-semibold u-upperCase" style="color: ' +
__e( sourceColor ) +
';">\n          ' +
__e( sourceId ) +
'\n        </span>\n\n        ';
 if (!isSourceType) { ;
__p += '\n          <span class="CDB-Text CDB-Size-small u-lSpace--s u-flex" style="color: ' +
__e( sourceColor ) +
';">\n            <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>\n          </span>\n        ';
 } ;
__p += '\n\n        <span class="CDB-Text CDB-Size-small u-mainTextColor u-lSpace">\n          ' +
((__t = ( sourceType )) == null ? '' : __t) +
'\n        </span>\n\n        <span class="CDB-Text CDB-Size-small u-altTextColor u-ellipsis u-lSpace" title="' +
((__t = ( layerName )) == null ? '' : __t) +
'">\n          ' +
((__t = ( layerName )) == null ? '' : __t) +
'\n        </span>\n      </div>\n    </dl>\n  ';
 } ;
__p += '\n  ';
 if (showStats) { ;
__p += '\n    <dl class="CDB-Widget-info CDB-Text CDB-Size-small u-secondaryTextColor u-upperCase u-tSpace">\n      <dt class="CDB-Widget-infoCount">' +
__e( nulls ) +
'</dt><dd class="CDB-Widget-infoDescription">null rows</dd>\n    </dl>\n  ';
 } ;
__p += '\n</div>\n<div class="CDB-Widget-content CDB-Widget-content--formula">\n  ';
 if (_.isNumber(value)) { ;
__p += '\n    <h4 class="CDB-Text CDB-Size-huge ' +
__e( !isCollapsed ? 'js-value' : '' ) +
'" title="' +
__e( value ) +
'">\n      ' +
((__t = ( prefix )) == null ? '' : __t) +
'' +
__e( value ) +
'' +
((__t = ( suffix )) == null ? '' : __t) +
'\n    </h4>\n    ';
 if (description) { ;
__p += '\n      <p class="CDB-Text CDB-Size-small u-tSpace js-description">' +
__e( description ) +
'</p>\n    ';
 } ;
__p += '\n  ';
 } else { ;
__p += '\n    <div class="CDB-Widget-listItem--fake"></div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}