var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-title CDB-Widget-contentSpaced">\n  <h3 class="CDB-Text CDB-Size-large u-ellipsis" title="' +
__e( title ) +
'">' +
__e( title ) +
'</h3>\n</div>\n\n<h2 class="CDB-Text CDB-Size-small is-semibold u-upperCase u-tSpace">' +
__e( error ) +
'</h2>\n\n<div class="CDB-Text CDB-Size-medium u-tSpace-xl">' +
__e( message ) +
'</div>\n\n';
 if (refresh) { ;
__p += '\n  <button class="CDB-Button CDB-Button--secondary CDB-Button--medium u-tSpace-xl js-refresh">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">REFRESH</span>\n  </button>\n';
 }  else { ;
__p += '\n  ' +
((__t = ( placeholder )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n\n';

}
return __p
}