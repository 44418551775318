var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Widget-header CDB-Widget-header--timeSeries">\n  <div class="CDB-Widget-timeSeriesTimeInfo CDB-Widget-timeSeriesTimeInfo--fake"></div>\n</div>\n<div class="CDB-Widget-content CDB-Widget-content--';
 if (hasTorqueLayer) { ;
__p += 'torqueTimeSeries';
 } else { ;
__p += 'timeSeries';
 } ;
__p += ' u-flex u-alignCenter">\n  ';
 if (hasTorqueLayer) { ;
__p += '\n    <div class="CDB-Widget-timeSeriesFakeControl"></div>\n  ';
 } ;
__p += '\n  <div class="CDB-Widget-timeSeriesFakeChart ';
 if (hasTorqueLayer) { ;
__p += 'CDB-Widget-timeSeriesFakeChart--torque';
 } ;
__p += '">\n    ';
 for (var i = 0; i < 50; i++) { ;
__p += '\n      <div class="CDB-Widget-timeSeriesFakeChartItem" style="height: ' +
__e( Math.floor(Math.random() * 100) ) +
'%"></div>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
}