var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="CDB-Overlay-title';
 if (!description && !legends) { ;
__p += ' is-collapsed';
 } ;
__p += '">\n  <h1 class="CDB-Text CDB-Size-large u-ellipsis" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\n\n  ';
 if (description || legends) { ;
__p += '\n    <div class="CDB-Overlay-options">\n      <button class="CDB-Shape js-toggle u-lSpace">\n        <div class="CDB-ArrowToogle is-blue is-down is-mini"></div>\n      </button>\n    </div>\n  ';
 } ;
__p += '\n</div>\n\n';
 if (description || legends) { ;
__p += '\n  <div class="CDB-Overlay-inner is-active';
 if (!legends) { ;
__p += ' is-description';
 } ;
__p += '">\n    ';
 if (description) { ;
__p += '<div class="CDB-Embed-description';
 if (legends) { ;
__p += ' is-legends';
 } ;
__p += ' CDB-Text CDB-Size-medium u-altTextColor" title="' +
((__t = ( description )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</div>';
 } ;
__p += '\n  </div>\n';
 } ;
__p += '\n';

}
return __p
}